import { ContentCard } from "../enum/enum";

export const roadMapData = [
  {
    title: "IDAO\nForecast",
    list: [
      {
        content: {
          text: "Запуск IDAO forecast в тестовой сети",
          period_date: "1-4 месяцы",
          line_bottom: true,
          first_element: true,
        },
      },
      {
        content: {
          text: "IDAO forecast stage 1",
          period_date: "5-8 месяцы",
          line_bottom: true,
        },
      },
      {
        content: {
          text: "IDAO forecast stage 2",
          period_date: "9-12 месяцы",
          line_bottom: true,
        },
      },
      {
        content: {
          text: "IDAO forecast stage 3",
          period_date: "13-16 месяцы",
          line_bottom: true,
        },
      },
      {
        content: {
          text: "IDAO forecast stage 4",
          period_date: "17-20 месяцы",
          line_bottom: false,
        },
      },
    ],
  },
  {
    title: "IDAO\nTrust",
    list: [
      {
        content: ContentCard.EMPTY,
      },
      {
        content: ContentCard.EMPTY,
      },
      {
        content: ContentCard.EMPTY,
      },
      {
        content: {
          text: "Запуск IDAO trust в тестовой сети",
          period_date: "13-16 месяцы",
          line_bottom: true,
          first_element: true,
        },
      },
      {
        content: {
          text: "IDAO Trust stage 1",
          period_date: "17-20 месяцы",
          line_bottom: true,
        },
      },
      {
        content: {
          text: "IDAO Trust stage 2",
          period_date: "21-24 месяцы ",
          line_bottom: false,
        },
      },
    ],
  },
  {
    title: "IDAO\nVote",
    list: [
      {
        content: ContentCard.EMPTY,
      },
      {
        content: {
          text: "Запуск IDAO Vote в тестовой сети",
          period_date: "5-8 месяцы",
          line_bottom: true,
          first_element: true,
        },
      },
      {
        content: {
          text: "IDAO Vote stage 1",
          period_date: "9-12 месяцы",
          line_bottom: true,
        },
      },
      {
        content: {
          text: "IDAO Vote stage 2",
          period_date: "13-16 месяцы",
          line_bottom: true,
        },
      },
      {
        content: {
          text: "IDAO Vote stage 3",
          period_date: "17-20 месяцы",
          line_bottom: true,
        },
      },
      {
        content: {
          text: "IDAO Vote stage 4",
          period_date: "21-24 месяцы ",
          line_bottom: false,
        },
      },
    ],
  },
  // {
  //   title: "DAO\nFoundation",
  //   list: [
  //     {
  //       content: {
  //         text: "Юридическое оформление",
  //         period_date: "1-4 месяцы",
  //         line_bottom: true,
  //         first_element: true,
  //       },
  //     },
  //     {
  //       content: {
  //         text: "Корпоративная структура",
  //         period_date: "5-8 месяцы",
  //         line_bottom: true,
  //       },
  //     },
  //     {
  //       content: {
  //         text: "Внутренние документы",
  //         period_date: "9-12 месяцы",
  //         line_bottom: false,
  //       },
  //     },
  //   ],
  // },
  // {
  //   title: "Листинги\nна биржах",
  //   list: [
  //     {
  //       content: ContentCard.EMPTY,
  //     },
  //     {
  //       content: {
  //         text: "Первые листинги",
  //         period_date: "5-8 месяцы",
  //         line_bottom: true,
  //         first_element: true,
  //       },
  //     },
  //     {
  //       content: ContentCard.LINE,
  //     },
  //     {
  //       content: {
  //         text: "Дополнительные листинги",
  //         period_date: "13-16 месяцы",
  //         line_bottom: false,
  //       },
  //     },
  //   ],
  // },
];

export const roadMapData2 = [
  {
    title: "Конец 2023 года",
    id: 1,
    list: [
      {
        content: {
          text: "Прототип IDAO forecast ",
          // period_date: "1-4 месяцы",
          // line_bottom: true,
          first_element: true,
        },
      },
    ],
  },
  {
    title: "1 квартал 2024 года",
    id: 2,
    list: [
      {
        content: {
          text: "Бета-версия платформы",
          // period_date: "1-4 месяцы",
          // line_bottom: true,
          first_element: true,
        },
      },
    ],
  },
  {
    title: "2-3 квартал 2024 года",
    id: 3,
    list: [
      {
        content: {
          text: "Запуск IDAO Forecast",
          // period_date: "5-8 месяцы",
          // line_bottom: true,
          first_element: true,
        },
      },
    ],
  },
];
export const roadMapData3 = [
  {
    title: "4 квартал 2024 года",
    id2: 4,
    list: [
      {
        content: {
          text: "Комиссия и пулы ликвидности",
          // period_date: "1-4 месяцы",
          // line_bottom: true,
          first_element: true,
        },
      },
    ],
  },
  {
    title: "1 квартал 2025 года",
    id2: 5,
    list: [
      {
        content: {
          text: "Бета-версия IDAO Trust",
          // period_date: "1-4 месяцы",
          // line_bottom: true,
          first_element: true,
        },
      },
    ],
  },
  {
    title: "2-3 квартал 2025 года",
    id2: 6,
    list: [
      {
        content: {
          text: "Запуск IDAO Trust",
          // period_date: "5-8 месяцы",
          // line_bottom: true,
          first_element: true,
        },
      },
    ],
  },
];

export const roadMapDataMobile = [
  {
    title: "Конец 2023 года",
    list: [
      {
        content: {
          text: "Прототип IDAO forecast ",
          // period_date: "1-4 месяцы",
          // line_bottom: true,
          first_element: true,
        },
      },
    ],
  },
  {
    title: "1 квартал 2024 года",
    list: [
      {
        content: {
          text: "Бета-версия платформы",
          // period_date: "1-4 месяцы",
          // line_bottom: true,
          first_element: true,
        },
      },
    ],
  },
  {
    title: "2-3 квартал 2024 года",
    list: [
      {
        content: {
          text: "Запуск IDAO Forecast",
          // period_date: "5-8 месяцы",
          // line_bottom: true,
          first_element: true,
        },
      },
    ],
  },
  {
    title: "4 квартал 2024 года",
    list: [
      {
        content: {
          text: "Комиссия и пулы ликвидности",
          // period_date: "1-4 месяцы",
          // line_bottom: true,
          first_element: true,
        },
      },
    ],
  },
  {
    title: "1 квартал 2025 года",
    list: [
      {
        content: {
          text: "Бета-версия IDAO Trust",
          // period_date: "1-4 месяцы",
          // line_bottom: true,
          first_element: true,
        },
      },
    ],
  },
  {
    title: "2-3 квартал 2025 года",
    list: [
      {
        content: {
          text: "Запуск IDAO Trust",
          // period_date: "1-4 месяцы",
          // line_bottom: true,
          first_element: true,
        },
      },
    ],
  },
];


